
.CalendarColumnFilter  {
  .bx--date-picker__input {
    font-family: 'Source Sans Pro', sans-serif;
    flex: 1;
    font-size: 1rem;
    height: 38px;
    width: 140px !important;
    background-color: #ffffff;
  }

  .bx--date-picker__input {
    background-color: #ffffff;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
  }

  .bx--date-picker-container {
    width: 100%;
  }

  .bx--date-picker {
    width: 100%;
  }
}
