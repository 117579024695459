.ReactTable {
  color: #152934;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.43;

  &.no-margins {
    & .rt-table {
      & .rt-tbody {
        & .rt-tr-group {
          & .rt-tr {
            & .rt-td {
              min-height: 40px;

              & .cell {
                margin: 5px;
              }
            }
          }
        }
      }
    }
  }

  & .rt-td {
    white-space: initial !important;
  }

  & .rt-table {
    & .rt-thead.-filters {
      & .rt-tr {
        padding: 0px;
        margin: 0px;

        & .rt-th {
          overflow: visible;
          max-width: 195px;
          min-width: 60px;
          padding: 14px 15px;
          min-height: 60px;
          border: 0px;
          border-right: solid 1px #e6e8eb;

          &:nth-last-child(1){
            border: 0px;
            max-width: 100%;
          }

          & input[type=text]{
            padding: 0px;
            height: 32px;
            border: solid 1px #d0d3d6;
          }
        }
      }
    }

    & .rt-thead.-headerGroups{
      display: none;
    }

    & .rt-thead.-header{
      background-color: #ffffff;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);

      & .rt-tr {
        align-items: center;
        color: #5a6872;
        font-weight: 600;

        & .rt-td {
          max-width: 195px;
        }

        & .rt-th {
          height:      80px;
          max-width:   195px;
          min-width:   60px;
          padding:     0 15px;
          display:     flex;
          align-items: center;
          margin:      0;

          &:nth-last-child(1){
            border: 0px;
            max-width: 100%;
          }

          & .rt-thead,
          & .rt-resizable-header-content {
            max-width: 140px;
            line-height: 20px;
            text-align: left;
            white-space: pre-wrap;

            & .column {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              & .label {
                max-width: 90px;
              }

              & .arrows_orders {
                width: 30px;
                height: 40px;

                & .Icon {
                  width: 10px;
                  margin: 0;
                  padding: 0;
                  color: #8c9ba5;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }

    & .rt-tbody {
      & .rt-tr-group {
        & .rt-tr {
          align-items: center;
          box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
          margin: 0;
          padding: 0;

          & .rt-td {
            margin: 0;
            padding: 0;
            border: 0;
            border-right: solid 1px #e6e8eb;
            min-height: 60px;
            max-width: 195px;
            min-width: 60px;

            &:nth-last-child(1){
              border: 0px;
              max-width: 100%;
            }

            & .cell {
              height: 28px;
              margin: 18px 16px;
              max-width: 160px;
              min-width: 100px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              &.inventory_quantity, &.available, &.backorder, &.sku {
                align-items: center;
                justify-content: center;

                p {
                  margin: 0;
                }
              }

              & .checkbox {
                height: 16px;
                width: 16px;

                & input[type=checkbox]{
                  cursor: pointer;
                  height: 16px;
                  margin: 0;
                  text-align: center;
                  width: 16px;
                  background-image: linear-gradient(to top, #f2f4f8, #feffff);
                }
              }

              & .edit {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: solid 0.5px #ced0da;
                background-image: linear-gradient(to top, #f2f4f8, #feffff);

                & .Icon {
                  width: 18px;
                  height: 18px;
                  margin: 1px;
                  color: #5a6872;
                }
              }

              &.sku {
                & .value{
                  height: 28px;
                  padding: 4px;
                  border-radius: 6px;
                  min-width: 80px;
                  text-align: center;
                  border: solid 1px #e6e8eb;
                  background-color: #ffffff;
                  color: #152934;
                }
              }

              &.title {
                height: auto;
                max-width: 100%;
                min-width: 27px;
                overflow: hidden;
                & .value{
                  overflow-wrap: break-word;
                }
              }
            }
          }
        }

        & .rt-tr.-odd{
          background-color: #f9fbff;
        }

        & .rt-tr.-even{
          background-color: #ffffff;
        }

        & .rt-tr:hover {
          background-color: #e6f1ff;
        }
      }
    }
  }
}

.filter-datepicker {
  margin-top: 12px;
}
.filter-datepicker .react-datepicker-wrapper {
  width: 100%;
}
.filter-datepicker .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
.filter-datepicker .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 67px;
}
