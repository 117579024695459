html, body {
  min-height: 100%;
}

#root {
  min-height: 100%;
}

.mr15 { margin-right: 15px !important; }

.fixed-title {
  z-index:          10;
  background-color: #f4f6f9;
  width:            100%;
}

.main-block {
  flex-grow: 1;
  height:    100%;
  z-index:   1;
  overflow:  hidden;
  position:  relative;
  display:   flex;
}

.flex-item { display: flex; }

.right-align { margin-left: auto !important; }

.Button {
  &--blue {
    border:           solid 1px #1585d8;
    background-image: linear-gradient(to top, #1991eb, #2da1f8);
  }
}

.order-items {
  border-radius:    4px;
  box-shadow:       0 0 5px 0 rgba(232, 232, 232, 0.53);
  border:           solid 1px #e6e8eb;
  background-color: #fff;

  &__single {
    position:                relative;
    display:                 flex;
    -webkit-justify-content: space-around;
    justify-content:         space-around;
    -webkit-flex-flow:       row wrap;
    flex-flow:               row wrap;
    -webkit-align-items:     stretch;
    align-items:             stretch;
    padding:                 20px;
    background-color:        #fff;
    border-bottom:           solid 1px #e6e8eb;

    &:nth-child(even) {
      background-color: #f9fbff;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__main-data {
    flex: 2;
  }

  &__secondary-data {
    flex:        1;
    border-left: 1px solid #e6e8eb;

    span {
      margin-bottom: 5px;
      padding-left:  30px;
      display:       block;
    }
  }

  &__line {
    display: flex;
  }

  &__sku {
    border-radius:    6px;
    border:           solid 1px #e6e8eb;
    background-color: #fff;
    line-height:      1.43;
    padding:          5px 10px;
    margin-top:       5px;
    height:           32px;
  }

  &__title {
    color:         #152934;
    line-height:   1.43;
    font-weight:   600;
    margin-bottom: 20px;
  }

  &__main-column {
    margin-left: 30px;
    margin-top:  10px;
    flex:        1;
  }

  &__manage {
    display: flex;
  }

  &__manage-item {
    flex: 0 0 35%;

    label {
      margin-right: 15px;
      color:        #354052;
    }
  }

  &__input {
    border-radius:    4px;
    border:           solid 1px #ced0da;
    background-color: #fff;
    padding:          10px 10px;
  }
}

.shipment-block {
  margin-bottom:    35px;
  margin-top:       25px;
  border-radius:    4px;
  box-shadow:       0 0 5px 0 rgba(232, 232, 232, 0.53);
  border:           solid 1px #e6e8eb;
  background-color: #fff;

  &__enter-data {
    padding:                 20px;
    display:                 flex;
    -webkit-justify-content: space-around;
    justify-content:         space-around;
    -webkit-flex-flow:       row wrap;
    flex-flow:               row wrap;
    -webkit-align-items:     stretch;
    align-items:             stretch;
    border-bottom:           1px solid #e6e8eb;
  }

  &__item {
    flex:          1 1 45%;
    margin-right:  5%;
    margin-bottom: 30px;

    label {
      font-weight: 600;
      color:       #354052;
      width:       160px;
      display:     inline-block;
      line-height: 14px;
    }

    input {
      width:            300px;
      display:          inline-block;
      border-radius:    4px;
      border:           1px solid #ced0da;
      background-color: #fff;
      padding:          8px 10px;
    }
  }

  &__submit-area {
    display:         flex;
    align-items:     center;
    justify-content: center;

    .Button {
      margin: 25px 10px;
    }
  }
}

.customSelect {
  width:            300px;
  border-radius:    4px;
  border:           1px solid #ced0da !important;
  background-color: #fff;

  > div {
    padding-left: 10px !important;

    &:before {
      display: none;
    }
  }
}

.close-button {
  position: absolute;
  right:    10px;
  top:      10px;
  width:    20px;
  height:   20px;
  cursor:   pointer;

  &:before,
  &:after {
    display:          block;
    content:          '';
    width:            20px;
    height:           1px;
    background-color: #e63c3c;
    top:              50%;
    position:         absolute;
  }

  &:after {
    transform: translateY(-50%) rotate(45deg);
  }

  &:before {
    transform: translateY(-50%) rotate(-45deg);
  }
}

.icon-manage {
  display: flex;
  height:  60px;

  &__element {
    flex:     1;
    position: relative;
    cursor:   pointer;

    &--remove { color: #e63c3c; }

    &--edit {
      &:after {
        position:   absolute;
        display:    block;
        content:    '';
        width:      1px;
        height:     70%;
        background: #e6e8eb;
        right:      0;
        top:        50%;
        transform:  translateY(-50%);
      }
    }

    .Icon {
      top:       50%;
      position:  absolute;
      left:      50%;
      transform: translateX(-50%) translateY(-50%);
      height:    20px;
      width:     20px;
    }
  }
}

.cell {
  &--centered {
    justify-content: center !important;
  }
}

.minus-record {
  color: red !important;
}

.flex-block {
  display: flex;
  width:   100% !important;

  span {
    flex: 1;

    &.date-order {
      text-align:   right;
      margin-right: 20px;
    }
  }
}

.export-link {
  line-height:    40px !important;
  padding:        0px 16px;
  text-transform: uppercase;
}

.mb0 { margin-bottom: 0 !important; }

.webshipdate {
  margin-left: 10px !important;

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;

      input {
        width: 67px;
      }
    }
  }
}

.modal-block {
  position:   absolute;
  top:        300px;
  transform:  translateX(-50%);
  width:      400px;
  left:       50%;
  background: #fff;
  box-shadow: 0 0 5px 0 hsla(0, 0%, 91%, .53);
  border:     1px solid #e6e8eb;
  display:    none;
  height: 500px;
  overflow: scroll;

  &--opened {
    display: block;
  }

  &__container {
    padding: 40px;
  }

  &__close {
    position: absolute;
    top:      2px;
    right:    2px;
    width:    20px;
    height:   20px;
    cursor:   pointer;

    &:before,
    &:after {
      position:   absolute;
      content:    '';
      display:    block;
      width:      100%;
      height:     1px;
      top:        50%;
      background: #464646;
    }

    &:before {
      transform: rotate(45deg) translateY(-50%);
    }

    &:after {
      transform: rotate(-45deg) translateY(-50%);
    }
  }
}

.singleSKU {
  margin-bottom: 10px;
  input {
    margin-right: 15px;
  }
}

.button-confirmation {
  box-shadow:    0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  background:    #e0e0e0 linear-gradient(0deg, #f2f4f7, #fff) !important;
  border-radius: 4px !important;
  border:        1px solid #d0d3d6 !important;
  color:         #5a6872 !important;
}

.status-block {
  background-color: #8e8e8e;
  color: white;
  padding: 5px;
}

input[type=number][disabled],
input[type=text][disabled] {
  background-color: rgba(150, 150,150,0.2);
}
