$css--font-face: false;
$css--helpers: true;
$css--body: false;
$css--use-layer: true;
$css--reset: false;
$css--typography: false;
$css--plex: true;


@import 'node_modules/carbon-components/scss/components/date-picker/date-picker';

.bx--date-picker__calendar {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: white !important;
}

.flatpickr-day.today::after {
  background-color: #4591cd;
  border-radius: 2px;
}

.flatpickr-day.today.bx--date-picker__day {
  color: #4591cd;
}

.CalendarColumnFilter  {
  .bx--date-picker__input {
    font-family: 'Source Sans Pro', sans-serif;
    flex: 1;
    font-size: 1rem;
    height: 38px;
    width: 100% !important;
  }

  .bx--date-picker__input {
    background-color: #ffffff;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
  }
}
