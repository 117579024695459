.NavItem.active-stroke {
  & svg {
    & path {
      stroke: #4591cd;
    }
  }
}

.NavItem.active-fill {
  & svg {
    & path {
      fill: #4591cd;
    }
  }
}

.NavItem-active {
  .NavItem-underline {
    // opacity: 1 !important;
  }
  .NavItem-image-wrapper {
    transform: scale(1.05);
  }
}
