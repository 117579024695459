.Main {
    background-color: #f4f6f9;
    height: 100%;
    width: 100%;
    overflow: scroll;

    & .Icon {
        object-fit: contain;
        margin-right: 8px;
        width: 14px;
        height: 14px;
    }

    &__Content {
        display:        flex;
        flex-direction: column;
        height:         100%;
        margin:         32px 40px 40px 40px;

        & .Title__Content {
            background-color: #f4f6f9;
            color: #152934;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.56;
            letter-spacing: normal;
            height: 84px;

            & .Title {
                padding-top: 32px;
                padding-bottom: 24px;
            }
        }

        & .Table__Content {
            background-color: #ffffff;
            border: solid 1px #e6e8eb;
            border-radius: 4px;
            box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
            height: 100%;

            & .Info__Content {
                margin-left: 24px;
                margin-top: 24px;
                margin-bottom: 24px;
                height: 44px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;

                & .Description {
                    color: #152934;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    height: 24px;
                    line-height: 1.5;
                    letter-spacing: normal;
                    width: 200px;

                    & .LastUpdate {
                        color: #5a6872;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        height: 20px;
                        line-height: 1.43;
                        letter-spacing: normal;
                        width: inherit;
                    }
                }

                & .Buttons_Content{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;

                    & .Button{
                        background-image: linear-gradient(to top, #f2f4f7, #ffffff);
                        border-radius: 4px;
                        border: solid 1px #d0d3d6;
                        color: #5a6872;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-size: 12px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        height: 40px;
                        line-height: 1.5;
                        letter-spacing: normal;
                        margin-right: 16px;

                        & .Label {
                            color: #5a6872;
                            font-family: 'Source Sans Pro', sans-serif;
                            font-size: 12px;
                            font-weight: 600;
                            font-style: normal;
                            font-stretch: normal;
                            height: 18px;
                            line-height: 1.5;
                            letter-spacing: normal;
                        }
                    }
                }
            }
        }
    }

    & .form__content {
        padding: 24px;
    }
}
