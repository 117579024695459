/*! =========================================================
 *
 Material Dashboard PRO React - v1.3.0 based on Material Dashboard PRO - V1.2.1
*
* =========================================================
*
* Copyright 2016 Creative Tim (https://www.creative-tim.com/product/material-dashboard-pro-react)
 *
 *                       _oo0oo_
 *                      o8888888o
 *                      88" . "88
 *                      (| -_- |)
 *                      0\  =  /0
 *                    ___/`---'\___
 *                  .' \|     |// '.
 *                 / \|||  :  |||// \
 *                / _||||| -:- |||||- \
 *               |   | \\  -  /// |   |
 *               | \_|  ''\---/''  |_/ |
 *               \  .-\__  '-'  ___/-. /
 *             ___'. .'  /--.--\  `. .'___
 *          ."" '<  `.___\_<|>_/___.' >' "".
 *         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *         \  \ `_.   \_ __\ /__ _/   .-` /  /
 *     =====`-.____`.___ \_____/___.-`___.-'=====
 *                       `=---='
 *
 *     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *               Buddha Bless:  "No Bugs"
 *
 * ========================================================= */
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  background-color: #EEEEEE;
  color: #3C4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

body, h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

blockquote footer:before, blockquote small:before {
  content: '\2014 \00A0'; }

h1 {
  font-size: 3em;
  line-height: 1.15em; }

h2 {
  font-size: 2.6em; }

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px; }

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px; }

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px; }

h6 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500; }

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

p {
  margin: 0 0 10px; }

body {
  background-color: #EEEEEE;
  color: #3C4858;
  font-size: 14px; }

blockquote p {
  font-style: italic; }

a {
  color: #9c27b0;
  text-decoration: none; }

a:hover, a:focus {
  color: #89229b;
  text-decoration: none; }

legend {
  border-bottom: 0; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }

*:focus {
  outline: 0; }

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important; }

legend {
  margin-bottom: 20px;
  font-size: 21px; }

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857; }

label {
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 400; }

footer {
  padding: 15px 0; }

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none; }

footer ul li {
  display: inline-block; }

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block; }

footer ul li a:hover {
  text-decoration: none; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden; }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden; } }

svg {
  touch-action: none; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777; }

h4 small, h5 small, h6 small {
  font-size: 75%; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

code, kbd, pre, samp {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace; }

img {
  vertical-align: middle;
  border: 0; }

/*
  --------------------------------------------------------------------------
    Below this point are all styles added on top of the material-dashboard
    and are specific to the composition of the raw components
  --------------------------------------------------------------------------  
*/
/* ABSTRACTS */
.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.no-scroll {
  position: fixed;
  overflow: hidden; }

/* COMPONENTS */
.spinning-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 5px solid rgba(125, 125, 125, 0.4);
  border-radius: 50%;
  border-top: 5px solid #1eb9d4;
  height: 30px;
  margin: 0px 16px 0px 0px;
  width: 30px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* LAYOUTS */
.dashboard-logo {
  transition: 0.4s;
  width: 20%;
  max-width: 300px; }

.dashboard-app-bar {
  background-color: #232323 !important; }

.dashboard-app-drawer {
  overflow-x: hidden; }

.dashboard-main-content {
  max-height: 100vh;
  overflow-y: scroll; }

.menu-button-closed {
  margin: 12px !important;
  height: 50px !important;
  align-self: center;
  transition: 0.4s; }

.menu-button-opened {
  margin: 12px !important;
  padding: 8px 0px !important;
  height: 50px !important;
  width: calc(260px - 24px); }

.top-anchor {
  margin: 0px 12px 12px 12px !important; }

.bottom-anchor {
  margin-top: auto; }

.no-link-color {
  color: inherit; }

.menu-drawer-control-container {
  justify-content: space-between !important; }

.dasbhoard-control-button {
  max-width: 200px !important;
  margin: 0 0 0 16px !important; }

.menu-drawer-title {
  margin: 0 0 0 16px !important;
  opacity: 0.0;
  transition: 0.25s; }
  .menu-drawer-title.open {
    opacity: 1.0; }

.login-page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  min-width: 100%;
  width: auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 25% 50%; }

.login-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 0.5vh 1vh rgba(35, 35, 35, 0.5);
  box-shadow: 0 0.5vh 1vh rgba(35, 35, 35, 0.5);
  background-color: #232323;
  min-height: 600px;
  min-width: 600px;
  width: 40%; }

.login-logo {
  max-width: 400px;
  width: 100%; }

.login-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 200px;
  width: 100%; }

.login-input {
  border-radius: 4px;
  border: none;
  font-size: 16px;
  height: 56px;
  margin: 12px 0px;
  min-width: 280px;
  padding: 0 12px;
  width: 50%; }

.login-button {
  -webkit-box-shadow: 0 0.25vh 0.5vh rgba(35, 35, 35, 0.5);
  box-shadow: 0 0.25vh 0.5vh rgba(35, 35, 35, 0.5);
  background-color: #232323;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  height: 36px;
  margin: 7px 0px;
  min-width: 64px;
  width: 20%; }

.login-message-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #dd2c00;
  font-size: 14px;
  height: 150px;
  padding: 0 24px;
  text-align: center;
  width: 100%; }

/* VIEWS */
.inventory-header, .inventory-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 60px;
  width: 100%; }

.inventory-header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px; }

.inventory-table-wrapper {
  width: 100%; }

.inventory-control-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.inventory-button-label {
  padding: 0 8px; }
