.backdrop {
    background-color: rgba(21, 41, 52, 0.5);
    bottom: 0;
    left: 0;
    padding: 65px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2001;
    display: flex;
    justify-content: center;
}

.modal {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    

    &-head {
        align-items: flex-start;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        padding: 0 24px;
        height: 90px;
        margin-top: 20px;
        margin-bottom: 20px;
        
        &-title {
            color: #152934;
            font-size: 18px;
            font-weight: 600;
            height: 28px;
            line-height: 1.56;
        }

        &-subtitle {
            color: #5a6872;
            font-size: 14px;
            font-weight: normal;
            height: 20px;
            line-height: 1.43;
        }

        &-icon {
            color: #A1A1A1;
            cursor: pointer;
        }
    }

    &-body {
        border-top: solid 1px #e6e8eb;
        border-bottom: solid 1px #e6e8eb;
        height: 100%;
        padding: 20px 24px;
        width: 500px;
        overflow-y: scroll;

        & .form-modal {
            display: flex;
            flex-direction: column;

            & .row {
                margin-top: 12px;
                margin-bottom: 12px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                & .field{
                    & input[type=checkbox] {
                        background-color: #f4f6f9;
                        border: solid 1px #ced0da;
                        border-radius: 4px;
                        cursor: pointer;
                        height: 16px;
                        margin: 0;
                        text-align: center;
                        width: 16px;
                        
                    }
                    
                    & input[type=checkbox]:after {
                        content: "";
                        background-color: #f4f6f9;
                        border: solid 1px #ced0da;
                        display: inline-block;
                        height: 16px;
                        visibility: visible;
                        width: 16px;
                    }
                    
                    & input[type=checkbox]:checked:after {
                        content: "\2714";
                        background-image: linear-gradient(to top, #29b311, #57d841);
                        border: solid 1px #27aa11;
                        color: white;
                        display: inline-block;
                        height: 16px;
                        visibility: visible;
                        width: 16px;
                    }
                }

                & .text { 
                    height: 18px;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #354052;
                    margin-left: 12px;
                }
            }
        }
    }

    &-footer {
        display: flex;
        justify-content: flex-end;
        height: 56px;
        padding: 0 24px;
        margin-top: 20px;
        margin-bottom: 20px;

        & .button {
            border-radius: 4px;
            min-width: 90px;
            max-width: 100px;
            height: 36px;
            margin-left: 16px;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            
            &.button-close {
                border: solid 1px #ced0da;
                background-image: linear-gradient(to top, #f2f4f7, #ffffff);

                & .btnText {
                    color: #354052;
                    line-height: 1.36;
                }
            }

            &.button-submit {
                border: solid 1px #1585d8;
                background-image: linear-gradient(to top, #1991eb, #2da1f8);

                & .btnText{
                    color: #ffffff;
                    line-height: normal;
                }
            }

        }
    }
}