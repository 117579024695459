.rw-dropdown-list-input {
  margin-left: 8px !important;
  text-align: left !important;
}

.rw-popup-container {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.rw-list-option {
  font-family: inherit !important;
  padding-left: 14px !important;
  text-align: left !important;
  font-size: 16px !important;

  &:nth-child(odd):not(.rw-list-option.rw-state-selected) {
    background-color: #f3f3f3;
  }
}
